<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section section-gap-30">
                <SettingMenu></SettingMenu>
                <div class="setting-content">
                    <div class="section-title-wrap mb-0 d-flex justify-content-between">
                        <div class="section-title margin-deliver">{{ $t(`Verify Company`) }}
                        <v-popover offset="10" :disabled="false" style="display:initial;">
                            <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i></button>
                            <template slot="popover">
                                <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                <div style="text-align:left">
                                    <a v-close-popover href="https://easy.jobs/docs/verify-your-company-profile/" target="_blank" style="margin-bottom: 5px;">
                                        {{ $t(`How to verify?`) }}
                                    </a>
                                </div>
                            </template>
                        </v-popover>
                        </div>
                    </div>
                    <div class="form-box verify__content">
                        <h5>Why verify?</h5>

                        <p>After you verify that you own your domain, you can start using your Easy.Jobs service.</p>

                        <h5>Add a TXT verification record</h5>
                        <p>{{ $t('To add a TXT Record, follow these steps:') }}</p>
                        <ol class="mt-4">
                            <li>Log in to your domain name provider (eg: <span style="color: #597dfc">godaddy.com</span> or  <span style="color: #597dfc">namecheap.com</span>)</li>
                            <li> Copy the TXT record below into the DNS configuration of <span  style="color: #597dfc">{{webUrl}}</span></li>
                            <li>
                                <span>Enter or copy+paste it in the Host field.</span>
                                <div class="input-group">
                                    <div class="input-group-prepend" @click="copyToClipboard(`_ejverify`)">
                                        <span class="input-group-text" id="basic-addon1" ><i class="eicon e-duplicate"></i></span>
                                    </div>
                                    <input type="text" class="form-control" readonly value="_ejverify"> <span style="color: #597dfc">.{{webUrl}}</span>
                                </div>
<!--                                <div class="form-inline">-->
<!--                                    <input type="text" class="form-control" readonly value="_ejverify"> <span style="color: #597dfc">.{{webUrl}}</span>-->
<!--                                    <button  @click="copyToClipboard(`_ejverify`)" class="semi-button semi-button-info ml-3"> <i class="eicon e-duplicate mr-2"></i>Copy</button>-->
<!--                                </div>-->
                            </li>
                            <li>
                                <span>Enter or copy+paste the value (string) into the Answer field.</span>
                                <div class="input-group">
                                    <div class="input-group-prepend" @click="copyToClipboard(company.verification_code)">
                                        <span class="input-group-text" id="basic-addon1"><i class="eicon e-duplicate"></i></span>
                                    </div>
                                    <input type="text" class="form-control" readonly :value="company.verification_code">
<!--                                    <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">-->
                                </div>
<!--                                <div class="form-inline">-->
<!--                                    <input type="text" class="form-control" readonly :value="company.verification_code">-->
<!--                                    <button  @click="copyToClipboard(company.verification_code)" class="semi-button semi-button-info ml-3"> <i class="eicon e-duplicate ml-2"></i>Copy</button>-->
<!--                                </div>-->
                            </li>

                            <li>Press the Verify button below</li>
                        </ol>

                        <div class="mt-5">
                            <submit-button :click="verifyCompany" :loading="isContentLoading">{{ $t("Verify Company") }}</submit-button>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </dashboard-layout>
</template>
<script>
import DashboardLayout from "../../../layouts/DashboardLayout";
import SettingMenu from "../../../components/company/SettingSidebar";
import client from "../../../app/api/Client";
import {mapActions, mapState} from 'vuex';
import {UPDATE_COMPANY_DATA} from "../../../constants/action-type";

export default {
    components: {
        DashboardLayout,
        SettingMenu,
    },

    data() {
        return {
            isContentLoading: false,
        }
    },

    computed: {
        ...mapState(['user', 'company']),
        isVerified() {
            return this.company.is_verified;
        },
        hasWebsite() {
            return !_.isEmpty(this.company.website);
        },
        webUrl() {
            if (!this.company.website || this.company.website.lenth < 1) {
                return '';
            }

            let data = this.company.website.replace(/^https?:\/\//, '');
            return  data.replace(/^www./, '');
        }
    },
    methods: {
        ...mapActions([UPDATE_COMPANY_DATA]),

        async verifyCompany() {
            if (!this.hasWebsite) {
                this.$toast.error(this.$t('Please input website URL first.'));
                return await this.$router.push({name: 'company.setting.basic'});
            }

            try {
                let {data: {data, message}} = await client().post(`/company/verify`, {
                    is_verified: this.isVerified
                });

                this.$toast.success(this.$t(message));

                if (data.hasOwnProperty('name') && data.hasOwnProperty('id')) {
                    this[UPDATE_COMPANY_DATA](data);
                    await this.$router.push({name: 'dashboard'});
                }
            } catch (error) {
                this.$toast.error(this.$t(error.response.data.message));
            }
        }
    },
    async mounted() {
        if (this.isVerified) {
            await this.$router.push({name: 'dashboard'});
        }

        if (!this.hasWebsite) {
            this.$toast.error(this.$t('Please input website URL first.'));
            return await this.$router.push({name: 'company.setting.basic', query: { focus: 'website_url' }});
        }
    }
}
</script>

<style scoped>
.form-box ol {
    padding-left: 20px;
    margin-bottom: 20px;
}

.form-box ol ul {
    padding-left: 20px;
}

.form-box ol li {
    list-style: decimal;
    padding-left: 5px;
}
.form-box h4,
.form-box h5,
.form-box p {
    margin-bottom: 20px;
}
.form-box code {
    border: 1px solid;
    padding: 5px 10px;
    border-radius: 20px;
    margin: 6px 0;
    display: inline-block;
}

.form-box .e-duplicate {
    cursor: pointer;
}
.verify__content ol li:not(:last-child) {
    margin-bottom: 10px;
}
.input-group {
    align-items: center;
    max-width: 400px;
}
.input-group .form-control {
    height: 40px;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}
.input-group .input-group-text {
    background: #ced4da;
    height: 40px;
    cursor: pointer;
    background: rgb(89, 125, 252);
    color: #fff;
    border-color: rgb(89, 125, 252);
}
</style>
